<script setup lang="ts">
// We don't require a full user object, just the fields required for an avatar.
// This makes it easier to use this component without requiring a full user object.
interface UserAvatarInfo {
  firstName: string
  lastName?: string
  avatarUrl?: string
  profileColor?: string
}

const { user, badgeSrc } = defineProps<{
  user: UserAvatarInfo | undefined
  badgeSrc?: string
}>()

// take the first 2 chars of the user's name for now
const initials = computed(() => {
  if (!user) return ''

  if (user.firstName && user.lastName) {
    return `${user.firstName[0]}${user.lastName[0]}`
  }

  if (!user.firstName) return user.lastName

  const [firstPart, secondPart = ''] = user.firstName.split(' ')
  return firstPart[0] + (secondPart[0] ?? firstPart[1] ?? '')
})

const colorClass = computed(() => user.profileColor || getRandomColor())
</script>

<template>
  <BaseAvatar
    :src="user?.avatarUrl || undefined"
    :text="initials"
    :class="colorClass"
    :badge-src="badgeSrc"
  />
</template>
